<template>
  <div
    v-if="breadcrumbs"
    class="breadcrumbs"
    :class="className"
  >
    <div class="breadcrumbs__title">
      {{ $t('You are here') }}
    </div>

    <ul class="breadcrumbs__list">
      <li class="breadcrumbs__item breadcrumbs__item--back">
        <AppLink
          :to="backLink"
          class="breadcrumbs__link"
        >
          <IconCssArrow2 class="icon icon--arrow-left" />
          <span>{{ $t('Back') }}</span>
        </AppLink>
      </li>
      <li class="breadcrumbs__item breadcrumbs__item--home">
        <AppLink
          :to="backLink"
          class="breadcrumbs__link"
        >
          <span>{{ $t('Home') }}</span>
        </AppLink>
      </li>
      <template v-if="breadcrumbs?.parents?.length">
        <li
          v-for="(parent, index) in (breadcrumbs.parents)"
          :key="index"
          class="breadcrumbs__item"
        >
          <AppLink
            v-if="parent && parent.url"
            :to="'/' + parent.url"
            class="breadcrumbs__link"
          >
            <span>{{ parent.label }}</span>
          </AppLink>
        </li>
      </template>
      <li
        v-if="breadcrumbs.label"
        class="breadcrumbs__item"
      >
        <span class="breadcrumbs__label">{{ breadcrumbs.label }}</span>
      </li>
    </ul>
  </div>
  <div v-else />
</template>

<script lang="ts" setup>
const { breadcrumbList, breadcrumbs } = useBreadcrumbs()
useHead({
  script: [{ key: 'BreadcrumbList', type: 'application/ld+json', innerHTML: breadcrumbList.value }],
})

defineProps({
  className: {
    type: String,
    default: '',
  },
  backLink: {
    type: String,
    default: '/',
  },
})
</script>

<style src="~layers/app/components/App/Breadcrumbs.css" />
